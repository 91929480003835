import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ToolBar(props) {
  const timePeriods = ['90','60','30','15','10','5','-1'];
  const dataTimeStamp = props.dataTimeStamp;
  const names = props.loNames;
  const [nameSelected , setNameSelected] = useState('All');
  const [timeSelected, setTimeSelected] = useState('90');
  const role = props.role;
  const loanCount = props.loanCount;

  const handleLOChange = (event) => {
    setNameSelected(event.target.value);
    props.changeFilters(event.target.value, timeSelected);
  }

  const handleTimeChange = (event) => {
    setTimeSelected(event.target.value);
    props.changeFilters(nameSelected, event.target.value);
  }

  return (
    <Container maxWidth="xl">
      {/* Large view */}
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mt={.75} px={2} py={1} sx={{ bgcolor: '#f1f2f3', display: { xs: 'none', sm: 'flex' }}}>
        <Box>
          <Typography level="body1" sx={{fontWeight: 700}}>
            {dataTimeStamp}
          </Typography>
        </Box>
        <Box>
        {role !== "LO" && (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="filter-name-label">Originators</InputLabel>
            <Select
              labelId="filter-name-select-label"
              id="filter-name-select"
              value={nameSelected}
              label="Originators"
              onChange={handleLOChange}
            >
              {names.map((name, index) => (
                <MenuItem key={'loName'+index} value={name}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="filter-days-label">Closing within</InputLabel>
            <Select
              labelId="filter-days-select-label"
              id="filter-days-select"
              value={timeSelected}
              label="Closing within"
              onChange={handleTimeChange}
            >
              {timePeriods.map((days, index) => (
                days === "-1" ?
                <MenuItem key={'999'+index} value={days}>Closed</MenuItem>
                :
                <MenuItem key={'numDays'+index} value={days}>{days} Days</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography level="body1" sx={{fontWeight: 700}}>
            Active loans: {loanCount}
          </Typography>
        </Box>
      </Stack>

      {/* Small view */}
      <Stack alignItems="center" spacing={1} mt={.75} p={1} sx={{bgcolor: '#f1f2f3', display: { xs: 'flex', sm: 'none'  }}}>
        <Box>
          <Typography level="body1" sx={{fontWeight: 700}}>
            {dataTimeStamp}
          </Typography>
        </Box>
        <Box>
          <Typography level="body1" sx={{fontWeight: 700}}>
            Active loans: {loanCount}
          </Typography>
        </Box>
        <Box>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="filter-name-label">Originators</InputLabel>
            <Select
              labelId="filter-name-select-label"
              id="filter-name-select"
              value={nameSelected}
              label="Originators"
              onChange={handleLOChange}
            >
              {names.map((name, index) => (
              <MenuItem key={'loName'+index} value={name}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="filter-days-label">Closing within</InputLabel>
            <Select
              labelId="filter-days-select-label"
              id="filter-days-select"
              value={timeSelected}
              label="Closing within"
              onChange={handleTimeChange}
            >
              {timePeriods.map((days, index) => (
                <MenuItem key={'numDays'+index} value={days}>{days} Days</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Container>

    );
  }