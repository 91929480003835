import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { PageLoader } from '../components/page-loader';

import { PageHeader } from '../components/page-header';
import ToolBar from '../components/toolbar';
import LoanInfo from '../components/loan-info';
import LoanDisclosure from '../components/loan-disclosure';
import LoanProcessing from '../components/loan-processing';
import LoanUnderwriting from '../components/loan-underwriting';
import LoanClosing from '../components/loan-closing';
import LoanPostClosing from '../components/loan-post-closing';
import LoanNotices from '../components/loan-notices';


export default function PipelinePage() {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();
  const [dataTimeStamp, setDataTimeStamp] = useState('');
  const [allOpenLoanData, setallOpenLoanData] = useState('');
  const [allClosedLoanData, setAllClosedLoanData] = useState('');
  const [filteredLoanData, setFilteredLoanData] = useState('');
  const [loNames, setLONames] = useState('');

  const width = window.innerWidth;
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(4);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredLoanData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(filteredLoanData.length / recordsPerPage);
  const loanCount = Math.ceil(filteredLoanData.length );
 
  const [userRole, setUserRole] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setIsLoading(true);
    getUserData();
    if (userRole !== '') {
      getDataFileInfo();
      getLoanData();
    }
    setNumLoansDisplay();
    //handleReadRemoteFile();
  },[userRole]);

  const setNumLoansDisplay = () => {
    var numLoans = 0;

    if (width <= 600) {
      numLoans = 1;
    }
    if (width >= 601 && width <= 1023) {
      numLoans = 2;
    }
    if (width >= 1024 && width <= 1199) {
      numLoans = 2;
    }
    if (width >= 1200) {
      numLoans = 4;
    }  
    
    setRecordsPerPage(numLoans);
  }

  // const useViewport = () => {
  //   const [width, setWidth] = useState(window.innerWidth);
  
  //   useEffect(() => {
  //     const handleWindowResize = () => setWidth(window.innerWidth);
  //     window.addEventListener("resize", handleWindowResize);
  //     return () => window.removeEventListener("resize", handleWindowResize);
  //   }, []);
  
  //   // Return the width so we can use it in our components
  //   return { width };
  // }

  const getUserData = () => {
    if (user) {
      let roles= '';
      let userRoles = '';
  
      roles = user['https://loanview.sfaloans.com/roles'];
      userRoles = roles.map((role, i) => (
        <span key={role}>{role}{(roles.length - 1 !== i) && ', '}</span>
      ))
      //console.log("User email: ", user.email);
      //console.log("Role: ", userRoles[0].props.children[0]);
      setUserRole(userRoles[0].props.children[0]);
      setUserEmail(user.email);
    }
  }

  const getDataFileInfo = () => {
    const url = `${process.env.REACT_APP_API_URL}/jt/file-info`;
    //console.log('loan data meta: ',url);
    axios.get(`${url}`)
    .then((response) => {
      const fileInfo = response.data;
      const metaDate = new Date(fileInfo.LastModified);
      setDataTimeStamp(' ' + metaDate.toLocaleString('en-US'));
    })
    .catch(error => console.error(`Error: $(error)`));
  }

  const getLoanData = () => {
    const url = `${process.env.REACT_APP_API_URL}/jt/loan-data`;
    //console.log("Loan data url: ", url);
    axios.get(`${url}`)
    .then((response) => {
      const data = response.data;
      //console.log("Loan data: ", data.data);
      filterDisplayData(data.data);
    })
    .catch(error => console.error(`Error: $(error)`));
  }

  function sortByCloseDate(a, b) {
    const n = Date.parse(a[ 'Est Closing Date' ]) - Date.parse(b[ 'Est Closing Date' ]);
    if (n !== 0) {
      return n;
    }
    // if date is equal then sort by name
    //return a.name.localeCompare(b.name);
    return 0;
  }

  function filterDisplayData(data) {
     //console.log("User role: ", userRole);
     let sorted = [];
     let closed = [];
     if (userRole === "Admin") {
        //console.log("I am an admin");
        // Filter data to only display open loan files with an application date
        sorted = data.filter((date) => date[ 'Application Date' ] !== '' && date[ 'Milestone Date - Funding' ] === '').sort(sortByCloseDate);
        closed = data.filter((date) => date[ 'Milestone Date - Funding' ] !== '').sort(sortByCloseDate);

        // Create array of loan officer names
        let names = [];
        names.push("All");
        // Remove last two rows of array - blank entries
        data.splice(data.length - 2, 2);
        data.forEach(element => {
            if (!names.includes(element['Loan Officer'])) {
              names.push(element['Loan Officer']);
            }
        });
        //console.log("LO names: ", names);
        setLONames(names);
        setallOpenLoanData(sorted);
        setAllClosedLoanData(closed);
        setFilteredLoanData(sorted);
        setIsLoading(false);
     } else {
        // Filter data to only display loan files with an application date
        sorted = data.filter(loan => loan[ 'Application Date' ] !== '' && loan[ 'Milestone Date - Funding' ] === '' && loan[ 'Loan Officer Email' ] === userEmail).sort(sortByCloseDate);
        //console.log('Sorted:', sorted);
        setallOpenLoanData(sorted);
        setFilteredLoanData(sorted);
        setIsLoading(false);
     }
  }

  function filterData(loName, dateRange) {
    //console.log('Filtering', loName, dateRange);
    //console.log('Filtering - data', allOpenLoanData);
    let newSort = [];
    let timeSorted = [];
    let days = parseInt(dateRange);
    let tempDate = new Date();
    let filterDate = new Date(tempDate.setDate(tempDate.getDate() + days));
    if (loName !== 'All') {
      // Filter on loan officer name
      if (days === -1) {
        // Closed loans
        newSort = allClosedLoanData.filter((name) => name[ 'Loan Officer' ] === loName).sort(sortByCloseDate);
      } else {
        // Open loans
        newSort = allOpenLoanData.filter((name) => name[ 'Loan Officer' ] === loName).sort(sortByCloseDate);
      }
    } else {
      // Admin - all loan officers
      if (days === -1) {
        // Closed loans
        newSort = allClosedLoanData.sort(sortByCloseDate);
      } else {
        // Open loans
        newSort = allOpenLoanData.sort(sortByCloseDate);
      }
    }
    timeSorted = newSort.filter((date) => new Date(date[ 'Est Closing Date' ]).getTime() <= filterDate.getTime());
    setFilteredLoanData(timeSorted);
    //console.log('Loan data:', timeSorted);
  }

  return (
    <>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <CssBaseline />
    <PageHeader/>
    {(isLoading !== true && loNames !== '') && (
    <ToolBar
      dataTimeStamp={dataTimeStamp}
      loNames={loNames}
      loanCount={loanCount}
      changeFilters={filterData}
      role={userRole}
    />
    )}
    <Container maxWidth="xl" component="main" sx={{mt: .5}}>
      {isLoading ? (
        <PageLoader />
      ) : (
        currentRecords.length >= 1 ? (
          <>
          <Stack alignItems="center" sx={{p: 2}}>
            <Pagination color="primary" count={nPages} page={currentPage} onChange={handlePageChange} />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent={'center'}>
            {currentRecords.map((loan, index) => (
              <Card key={loan["Loan Number"]} sx={{minWidth: 335, backgroundColor:"#f1f2f3"}}>
                <CardContent sx={{p:0}}>
                {userRole !== "LO" && (
                  <LoanNotices loanData={loan} />
                )}
                  <LoanInfo 
                    loanNumber={loan["Loan Number"]}
                    borrLastName={loan["Borrower Last Name/Suffix"]}
                    borrFirstName={loan["Borrower First/Middle Name"]}
                    coBorrLastName={loan["Co-Borrower Last Name/Suffix"]}
                    coBorrFirstName={loan["Co-Borrower First/Middle Name"]}
                    address={loan["Subject Property Address"]}
                    city={loan["Subject Property City"]}
                    state={loan["Subject Property State"]}
                    loanAmount={loan["Loan Amount"]}
                    loanType={loan["Loan Type"]}
                    loanPurpose={loan["Borrower SSN Verification Purpose of the Transaction"]}
                    lender={loan["Closing Cost Program"]}
                    selfEmployed={loan["Borr Self Employed"]}
                    brokeredLoan={loan["File Contacts Broker Name"]}
                    loanOfficer={loan["Loan Officer"]}
                    lockDate={loan["Lock Date"]}
                    lockExpDate={loan["Lock Expiration Date"]}
                    estCloseDate={loan["Est Closing Date"]}
                    lastMilestone={loan["Last Finished Milestone"]}
                    nextMilestone={loan["Next Expected Milestone"]}
                    appraisalCont={loan["Appraisal Contingency Date"]}
                    financingCont={loan["Financing Contingency Date"]}
                    borrContactPrefCellPhone={loan["Borrower - Cell Phone Preferred"]}
                    borrContactPrefHomePhone={loan["Borrower - Home Phone Preferred"]}
                    borrContactPrefWorkPhone={loan["Borrower - Work Phone Preferred"]}
                    borrContactPrefHomeEmail={loan["Borrower - Home Email Preferred"]}
                    borrContactPrefWorkEmail={loan["Borrower - Work Email Preferred"]}
                    borrContactPrefTextMsg={loan["Borrower - Text Message Preferred"]}
                    coBorrContactPrefCellPhone={loan["Borrower - Cell Phone Preferred"]}
                    coBorrContactPrefHomePhone={loan["Borrower - Home Phone Preferred"]}
                    coBorrContactPrefWorkPhone={loan["Borrower - Work Phone Preferred"]}
                    coBorrContactPrefHomeEmail={loan["Borrower - Home Email Preferred"]}
                    coBorrContactPrefWorkEmail={loan["Borrower - Work Email Preferred"]}
                    coBorrContactPrefTextMsg={loan["Borrower - Text Message Preferred"]}
                  />
                  <LoanDisclosure
                    appDate={loan["Application Date"]}
                    leDueDate={loan["Initial Disclosure Due Date"]}
                    eConsentDate={loan["eConsent Date"]}
                    intentProceedDate={loan["Borrower Intent to Continue Date"]}
                    creditOrderDate={loan["Document Date Ordered - Credit Report"]}
                    creditRcvdDate={loan["Document Date Received - Credit Report"]}
                    earliestFeeCollDate={loan["Earliest Fee Collection Date"]}
                    earliestCloseDate={loan["Earliest Closing Date"]}
                    estCloseDate={loan["Est Closing Date"]}
                    leSentDate={loan["TIL Intl Disclosure Provided Date"]}
                    leRcvdDate={loan["TIL Intl TIL Disclosure Rcvd Date"]}
                    leRevSentDate={loan["TIL Redisclosure Provided Date"]}
                    leRevRcvdDate={loan["TIL Redisclosure Rcvd Date"]}
                    ssplSentDate={loan["Settlement Services Provider List Sent Date"]}
                    submitProcDate={loan["Milestone Date - Processing"]}
                    role={userRole}
                  />
                  <LoanProcessing
                    loanType={loan["Loan Type"]}
                    titleOrderDate={loan["Document Date Ordered - 04-Title"]}
                    titleRcvdDate={loan["Document Date Received - 04-Title"]}
                    appraisalWaiverDate={loan["Document Date Received - Appraisal Waiver"]}
                    appraisalOrderDate={loan["Document Date Ordered - Appraisal"]}
                    appraisalRcvdDate={loan["Document Date Received - Appraisal"]}
                    appraisalAckOrderDate={loan["Document Date Ordered - Appraisal Borrowers Acknowledgment"]}
                    appraisalAckRcvdDate={loan["Document Date Received - Appraisal Borrowers Acknowledgment"]}
                    floodRcvdDate={loan["Document Date Received - Flood Certificate"]}
                    floodCert={loan["Property Info Flood Certification Identifier"].trim()}
                    hoiRcvdDate={loan["Document Date Received - Homeowners Insurance Policy"]}
                    uspsRcvdDate={loan["Document Date Received - USPS"]}
                    voeFinalRcvdDate={loan["Document Date Received - Income - VOE - Final"]}
                    selfEmployed={loan["Borr Self Employed"]}
                    transOrderDate={loan["Document Date Ordered - 4506T Settlement Service"]}
                    transRcvdDate={loan["Document Date Received - 4506T Settlement Service"]}
                    agencyCaseNum={loan["Agency Case #"]}
                    coeOrderDate={loan["Document Date Ordered - VA Certificate of Eligibility/COE"]}
                    coeRcvdDate={loan["Document Date Received - VA Certificate of Eligibility/COE"]}
                    submitUnderDate={loan["Milestone Date - Submittal"]}
                    lastMilestone={loan["Last Finished Milestone"]}
                  />
                  <LoanUnderwriting
                    condApprvDate={loan["Milestone Date - Cond Approved"]}
                    condOrderDate={loan["Document Date Ordered - 05-Conditions"]}
                    condRcvdDate={loan["Document Date Received - 05-Conditions"]}
                    apprvDate={loan["Milestone Date - Approval"]}
                    condListAll={loan["Preliminary Conditions ALL"].trim()}
                    condListFulfilled={loan["Preliminary Conditions Fulfilled"]}
                    condListNotFulfilled={loan["Preliminary Conditions Not Fulfilled"]}
                    condListOpenCount={loan["Open Preliminary Conditions Count"]}
                    condListFulfilledCount={loan["Fulfilled Preliminary Conditions Count"]}
                  />
                  <LoanClosing
                    appraisalRcvdDate={loan["Document Date Received - Appraisal"]}
                    titleRcvdDate={loan["Document Date Received - 04-Title"]}
                    hoiRcvdDate={loan["Document Date Received - Homeowners Insurance Policy"]}
                    cdSentDate={loan["Closing Disclosure Sent Date"]}
                    cdRcvdDate={loan["Closing Disclosure Received Date"]}
                    revCdSentDate={loan["Revised Closing Disclosure sent date"]}
                    revCdRcvdDate={loan["Revised Closing Disclosure received date"]}
                    cloPkgSent={loan["Milestone Date - Closing Pkg Sent"]}
                    fundingDate={loan["Milestone Date - Funding"]}
                    lastMilestone={loan["Last Finished Milestone"]}
                  />
                  <LoanPostClosing
                    brokeredLoan={loan["File Contacts Broker Name"]}
                    finalCloPkgRcvdDate={loan["Document Date Received - * Final Closing Package"]}
                  />
                </CardContent>
              </Card>
            ))}
          </Stack>
          </>
        ) : (
          <Typography variant="body1" fontWeight="700" pt={2} pb={4} textAlign={"center"}>
            You currently have 0 loans in process.
          </Typography>
        )
      )}
    </Container>

    </>
  );
}